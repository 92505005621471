import { events } from '../utilities/custom-events';

export default class {
    constructor({
        elId,
        linkContHandle,
        fixedClass,
        activeClass,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const linkConts = el.querySelectorAll(linkContHandle);
        const links = Array.from(linkConts).map(linkCont => linkCont.querySelector('a'));
        const sections = Array.from(links)
        // Find sections by link hash
            .map(link => document.getElementById(link.href.split('#')[1]))
        // Filter out null nodes
            .filter(link => link);

        // State variables
        let lastY = window.pageYOffset;

        // Helper functions
        function setActiveLink(activeIndex) {
            Array.from(links).forEach((link, i) => {
                if (i === activeIndex) {
                    link.classList.add(activeClass);
                } else {
                    link.classList.remove(activeClass);
                }
            });
        }

        // Event handler functions
        function handleScroll() {
            // Handle fixing nav
            if (el.getBoundingClientRect().top < 0 && window.pageYOffset - lastY < 0) {
                // if (el.getBoundingClientRect().top < 0) {
                el.classList.add(fixedClass);
            } else {
                el.classList.remove(fixedClass);
            }
            lastY = window.pageYOffset;

            // Handle active link
            if (!sections) return;

            const threshold = window.innerHeight / 2;

            Array.from(sections).forEach((section, i) => {
                const rect = section.getBoundingClientRect();

                if (rect.top > threshold || rect.bottom < threshold) return;

                setActiveLink(i);
            });
        }
        function handleUnload(e) {
            const { view } = e.detail;

            if (view !== 'page') return;

            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener(events.unloadView, handleUnload);
        }
        const handleClicks = Array.from(links).map((link, i) => () => {
            setActiveLink(i);
        });

        // Add event listeners
        window.addEventListener('scroll', handleScroll);
        window.addEventListener(events.unloadView, handleUnload);
        Array.from(links).forEach((link, i) => {
            link.addEventListener('click', handleClicks[i]);
        });

        // Initialize
        handleScroll();
    }
}
