import { events, emitEvent } from '../utilities/custom-events';
import { injectMarkup } from '../utilities/components';

export default class {
    constructor({
        elId,
        shadowHandle,
        contentHandle,
        closeHandle,
        activeClass,
        state,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const shadow = el.querySelector(shadowHandle);
        const content = el.querySelector(contentHandle);
        const close = el.querySelector(closeHandle);

        // Event handler functions
        function handleKeyup(e) {
            // Only car about escape key
            if (e.keyCode !== 27) return;

            state.index--;

            emitEvent(events.closeView, { view: 'sidebar' });
            if (window.history) window.history.back();
        }
        function handleOpenSidebar(e) {
            if (e.detail.view !== 'sidebar') return;

            state.index++;

            emitEvent(events.lockScroll);
            el.classList.add(activeClass);

            document.addEventListener('keyup', handleKeyup);
        }
        function handleCloseSidebar(e) {
            e.preventDefault();

            if (e.detail.view !== 'sidebar') return;

            emitEvent(events.unlockScroll);
            el.classList.remove(activeClass);

            document.removeEventListener('keyup', handleKeyup);
        }
        function handleLoadSidebar(e) {
            if (e.detail.view !== 'sidebar') return;

            const { markup, size } = e.detail;

            el.setAttribute('data-size', size);
            injectMarkup(content, markup);
            handleOpenSidebar(e);
        }
        function handleClick(e) {
            e.preventDefault();

            emitEvent(events.closeView, { view: 'sidebar' });

            // Only decrement state and trigger back for on-site referrers
            if (document.referrer.match(/^https?:\/\/([^\/]+\.)?wrayward\.com(\/|$)/i)) { // eslint-disable-line no-useless-escape
                state.index--;

                if (window.history) window.history.back();
            }
        }
        function handlePopState(e) {
            if (!e.state) return;

            const { view } = e.state;

            if (view !== 'sidebar') return;

            emitEvent(events.openView, { view: 'sidebar' });
        }

        // Add event listeners
        window.addEventListener(events.openView, handleOpenSidebar);
        window.addEventListener(events.closeView, handleCloseSidebar);
        window.addEventListener(events.loadView, handleLoadSidebar);
        close.addEventListener('click', handleClick);
        shadow.addEventListener('click', handleClick);

        // Set popstate event listener
        if (window.history) window.addEventListener('popstate', handlePopState);
    }
}
