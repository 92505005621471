import Vivus from 'vivus';

export default class {
    constructor({
        id,
        solutionHandle,
        activeClass,
    }) {
        // Elements and class variables
        const el = document.getElementById(id);
        const graphic = el.querySelector('aside');
        const svg = graphic.querySelector('svg');
        const headings = graphic.querySelectorAll('h3');
        const paths = svg.querySelectorAll('path');
        const solutions = el.querySelectorAll(solutionHandle);

        // Helper functions
        const handlePaths = Array.from(paths).map((p, i) => () => {
            paths.forEach((pp, ii) => {
                headings[ii].classList.toggle(activeClass, i === ii);
                pp.classList.toggle(activeClass, i === ii);
                solutions[ii].classList.toggle(activeClass, i === ii);
            });
        });
        function handleLoad() {
            // Activate headings and first step
            el.classList.add(activeClass);
            headings[0].classList.add(activeClass);
            paths[0].classList.add(activeClass);
            solutions[0].classList.add(activeClass);

            paths.forEach((p, i) => {
                // Reset stroke dasharray so that miter line join works
                p.setAttribute('stroke-dasharray', 0);
                p.style.strokeDasharray = 0;

                // Add event listeners
                headings[i].addEventListener('click', handlePaths[i]);
                p.addEventListener('click', handlePaths[i]);
                p.addEventListener('mouseover', handlePaths[i]);
            });
        }

        // Initialize
        const options = {
            type: 'oneByOne',
        };
        new Vivus(svg, options, handleLoad); // eslint-disable-line no-new
    }
}
