export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const logoList = el.querySelector('ul');
        const moveList = () => {
            const { bottom } = logoList.getBoundingClientRect();
            const { innerHeight: height } = window;
            const { offsetWidth: length } = logoList;
            const { offsetWidth: offset } = logoList.firstElementChild;
            const progress = 1 - (bottom / height);
            const shift = (progress * length * -0.25) - offset;

            logoList.style.transform = `translateX(${shift}px)`;
        };

        window.addEventListener('scroll', moveList);

        moveList();
    }
}
