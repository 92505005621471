export default class {
    constructor({
        elId,
        mainHandle,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const main = el.querySelector(mainHandle);

        // Event handler functions
        function handleTouchMove(e) {
            // Allow for scroll in this container on iOS while body is locked
            e.stopPropagation();
        }

        // Add event listeners
        main.addEventListener('touchmove', handleTouchMove);
    }
}
