const ANIMATION_DELAY = 1000;
const LOAD_DELAY = 3000;

export default class {
    constructor({
        elId,
        wordHandle,
        logoHandle,
        loadedClass,
        activeClass,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const words = el.querySelectorAll(wordHandle);
        const logo = el.querySelector(logoHandle);

        // State variables
        let activeWord = -1;

        // Helper functions
        function animate() {
            if (activeWord !== -1) {
                words[activeWord].classList.remove(activeClass);
            }

            if (activeWord === words.length - 1) {
                logo.classList.add(activeClass);

                setTimeout(() => {
                    el.classList.add(loadedClass);
                }, LOAD_DELAY);

                return;
            }

            activeWord++;
            words[activeWord].classList.add(activeClass);

            setTimeout(animate, ANIMATION_DELAY);
        }

        // Event listener functions
        function handleClick() {
            el.classList.add(loadedClass);
            logo.classList.add(activeClass);

            el.removeEventListener('click', handleClick);
        }

        // Enable event listeners
        el.addEventListener('click', handleClick);

        // Initalize
        animate();
    }
}
