import { events } from '../utilities/custom-events';

export default class {
    constructor({
        elId,
        labelHandle,
        inputHandle,
        errorHandle,
        activeClass,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const label = el.querySelector(labelHandle);
        const input = el.querySelector(inputHandle)
            || el.querySelector('[type="checkbox"]')
            || el.querySelector('[type="radio"]');
        const error = el.querySelector(errorHandle);

        if (!label || !input) return;

        // Event handler functions
        function handleSetInputError(e) {
            const { name, error: errorText } = e.detail;

            if (name.replace('[]', '') !== input.name.replace('[]', '')) {
                return;
            }

            error.innerHTML = errorText;
        }
        function handleCheck() {
            if (input.nodeName === 'TEXTAREA') {
                input.style.height = '';
                input.style.height = `${input.scrollHeight + 1}px`;
            }

            // If input is empty remove active class
            if (!input.value.trim()) {
                el.classList.remove(activeClass);

                return;
            }

            el.classList.add(activeClass);
        }

        // Add event listeners
        window.addEventListener(events.setInputError, handleSetInputError);
        label.addEventListener('click', handleCheck);
        input.addEventListener('focus', handleCheck);
        input.addEventListener('blur', handleCheck);
        input.addEventListener('input', handleCheck);

        // Initialize
        handleCheck();
    }
}
