import Player from '@vimeo/player';
import { isIos, isMobile } from '../utilities/devices';

const AR = 16 / 9;

export default class {
    constructor({
        id,
        activeClass = 'is-active',
        inactiveClass = 'is-inactive',
    }) {
        const el = document.getElementById(id);
        const articles = el.querySelectorAll('article');
        const figures = [...articles].map(a => a.querySelector('figure'));
        const images = [...figures].map(f => f.querySelector('img'));
        const iframes = [...figures].map(f => f.querySelector('iframe'));
        const players = [...iframes].map(iframe => new Player(iframe));
        const onMobile = isMobile() || isIos();

        if (onMobile) {
            return;
        }

        function setVideoSize() {
            figures.forEach(f => {
                const videoCont = f.querySelector('div');

                if (f.offsetWidth / f.offsetHeight > 16 / 9) {
                    videoCont.style.width = `${f.offsetWidth}px`;
                    videoCont.style.height = `${f.offsetWidth / AR}px`;
                } else {
                    videoCont.style.width = `${f.offsetHeight * AR}px`;
                    videoCont.style.height = `${f.offsetHeight}px`;
                }

                videoCont.classList.add(activeClass);
            });
        }

        window.addEventListener('resize', setVideoSize);
        images.forEach(img => { img.onload = setVideoSize; });
        figures.forEach(f => {
            const circle = f.querySelector('i');

            f.onmousemove = ({ clientX, clientY }) => {
                const { top, left } = f.getBoundingClientRect();

                circle.style.top = `${clientY - top}px`;
                circle.style.left = `${clientX - left}px`;
            };
        });
        articles.forEach((a, i) => {
            a.onmouseenter = () => {
                articles.forEach(aa => { aa.classList.toggle(activeClass, a === aa); });
                articles.forEach(aa => { aa.classList.toggle(inactiveClass, a !== aa); });
                players[i].play();
            };
            a.onmouseleave = () => {
                players[i].pause();
            };
        });

        setVideoSize();
    }
}
