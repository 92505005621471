export const events = {
    openView: 'open-view',
    closeView: 'close-view',
    loadView: 'load-view',
    lockScroll: 'lock-scroll',
    unlockScroll: 'unlock-scroll',
    updateHeader: 'update-header',
    updateFooter: 'update-footer',
    setInputError: 'set-input-error',
};

/**
 * Emit event - wrapper around CustomEvent API
 * @param {string} eventHandle
 * @param {object} eventDetails
 */
export function emitEvent(eventHandle, eventDetails) {
    const event = new CustomEvent(eventHandle, { detail: eventDetails });

    window.dispatchEvent(event);
}
