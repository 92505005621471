import debounce from '../utilities/debouncer';
import { getBreakpoint } from '../utilities/breakpoints';
import { events, emitEvent } from '../utilities/custom-events';
import { injectMarkup } from '../utilities/components';
import { scrollTop } from '../utilities/scroll';

const HEIGHT = 72;

export default class {
    constructor({
        elId,
        toggleInputHandle,
        linksContHandle,
        ctaHandle,
        activeClass = 'is-active',
        visibleLinksClass,
        visibleCtaClass,
        concealHeaderLinks,
        concealHeaderCta,
        fadeHeader,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const toggleInput = el.querySelector(toggleInputHandle);
        const linksCont = el.querySelector(linksContHandle);
        const links = linksCont.querySelectorAll('a');
        const cta = el.querySelector(ctaHandle);

        // State variables
        let concealLinks = concealHeaderLinks;
        let concealCta = concealHeaderCta;
        let fade = fadeHeader;

        // Helper functions
        function concealHeader() {
            if (concealLinks) {
                el.classList.remove(visibleLinksClass);
            } else {
                el.classList.add(visibleLinksClass);
            }

            if (concealCta) {
                el.classList.remove(visibleCtaClass);
            } else {
                el.classList.add(visibleCtaClass);
            }
        }

        // Event handler functions
        function handleUpdateHeader(e) {
            Array.from(links).forEach(link => {
                if (window.location.href.includes(link.href)) {
                    link.classList.add(activeClass);
                } else {
                    link.classList.remove(activeClass);
                }
            });

            const { invertHeader, headerCta } = e.detail;

            // Update state variables
            concealLinks = e.detail.concealHeaderLinks;
            concealCta = e.detail.concealHeaderCta;
            fade = e.detail.fadeHeader;

            concealHeader();

            el.style.opacity = 1;
            el.setAttribute('data-invert', invertHeader);
            toggleInput.checked = false;
            injectMarkup(cta, headerCta);
        }
        function handleScroll() {
            if (!fade) return;

            const diff = HEIGHT + el.getBoundingClientRect().top;

            if (diff < 0) return;

            el.style.opacity = (diff / HEIGHT) ** 3;
        }
        function handleTouchMove(e) {
            // Allow for scroll in this container on iOS while body is locked
            e.stopPropagation();
        }
        function handleToggleClick() {
            if (toggleInput.checked) {
                scrollTop(el);
                emitEvent(events.lockScroll);
                el.style.opacity = 1;
                el.classList.add(visibleLinksClass);
            } else {
                emitEvent(events.unlockScroll);
                handleScroll();
                el.classList.remove(visibleLinksClass);
            }
        }
        const handleResize = debounce(() => {
            if (getBreakpoint() === 'sm') return;

            toggleInput.checked = false;
            handleToggleClick();
            concealHeader();
        }, 500);

        // Add event listeners
        window.addEventListener(events.updateHeader, handleUpdateHeader);
        window.addEventListener('scroll', handleScroll);
        linksCont.addEventListener('touchmove', handleTouchMove);
        toggleInput.addEventListener('click', handleToggleClick);
        window.addEventListener('resize', handleResize);

        // Initialize
        handleResize();
    }
}
