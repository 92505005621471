import { events, emitEvent } from '../utilities/custom-events';
import { injectMarkup } from '../utilities/components';

export default class {
    constructor({
        elId,
        closeHandle,
        contentHandle,
        activeClass,
        state,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const content = el.querySelector(contentHandle);
        const close = el.querySelector(closeHandle);

        // Event handler functions
        function handleKeyup(e) {
            // Only car about escape key
            if (e.keyCode !== 27) return;

            state.index--;

            emitEvent(events.closeView, { view: 'modal' });
            if (window.history) window.history.back();
        }
        function handleOpenModal(e) {
            if (e.detail.view !== 'modal') return;

            state.index++;

            emitEvent(events.lockScroll);
            el.classList.add(activeClass);
            if (e.detail.cb) e.detail.cb(content);

            document.addEventListener('keyup', handleKeyup);
        }
        function handleCloseModal(e) {
            e.preventDefault();

            if (e.detail.view !== 'modal') return;

            emitEvent(events.unlockScroll);
            el.classList.remove(activeClass);

            document.removeEventListener('keyup', handleKeyup);
        }
        function handleLoadModal(e) {
            if (e.detail.view !== 'modal') return;

            const { markup } = e.detail;

            injectMarkup(content, markup);
            handleOpenModal(e);
        }
        function handleClick(e) {
            e.preventDefault();

            emitEvent(events.closeView, { view: 'modal' });

            // Only decrement state and trigger back for on-site referrers
            if (document.referrer.match(/^https?:\/\/([^\/]+\.)?wrayward\.com(\/|$)/i)) { // eslint-disable-line no-useless-escape
                state.index--;

                if (window.history) window.history.back();
            }
        }
        function handlePopState(e) {
            if (!e.state) return;

            const { view } = e.state;

            if (view !== 'modal') return;

            emitEvent(events.openView, { view: 'modal' });
        }

        // Add event listeners
        window.addEventListener(events.openView, handleOpenModal);
        window.addEventListener(events.closeView, handleCloseModal);
        window.addEventListener(events.loadView, handleLoadModal);
        close.addEventListener('click', handleClick);

        // Set popstate event listener
        if (window.history) window.addEventListener('popstate', handlePopState);
    }
}
