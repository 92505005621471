const TAGS = 'h1,h2,h3,h4,h5,h6,p,strong,b,em,li,a,span,td';

function replace(from, to) {
    document.querySelectorAll(TAGS).forEach(el => {
        if (el.children.length > 0) {
            return;
        }

        el.innerHTML = el.innerHTML.replace(from, to);
    });
}

/*
 * Superscript all registered trademarks
 * @return {void}
 */
export function superscript() {
    replace('®', '<sup>&reg;</sup>');
}

/*
 * Remove all newline characters that are not stripped by server
 * https://stackoverflow.com/questions/41555397/strange-symbol-shows-up-on-website-l-sep/45822037
 * @return {void}
 */
export function newlines() {
    replace('&#8232;', '');
}

function wordstoLines(words, splits) {
    const startStops = [...splits, words.length].map((split, i) => [
        i === 0 ? 0 : splits[i - 1] + 1,
        split + 1,
    ]);

    return startStops.map(([start, stop]) => words.slice(start, stop).join(' '));
}

export function wrapBrokenText() {
    const els = document.querySelectorAll('[data-wrap-broken-text="true"]');

    els.forEach(el => {
        const words = el.innerHTML.split(/[\s,\n]+/);
        const splits = words
            .map((w, i) => [w.includes('<br'), i])
            .filter(s => s[0])
            .map(s => s[1]);
        const lines = wordstoLines(words, splits);

        el.innerHTML = lines.map(line => `<div><div>${line}</div></div>`).join('');
        el.setAttribute('data-wrap-broken-text', 'false');
    });
}

export function wrapFreeText() {
    const els = document.querySelectorAll('[data-wrap-free-text="true"]');

    els.forEach(el => {
        const lineHeight = parseFloat(window.getComputedStyle(el).getPropertyValue('line-height'));
        const numLines = Math.floor(el.offsetHeight / lineHeight);
        const words = el.innerHTML.split(/[\s,\n]+/);
        const splits = [...Array(numLines - 1).keys()]
            .map(i => Math.floor(words.length / numLines) * (i + 1));
        const lines = wordstoLines(words, splits);

        el.innerHTML = lines.map(line => `<div><div>${line}</div></div>`).join('');
        el.setAttribute('data-wrap-free-text', 'false');
    });
}
