import { load } from 'recaptcha-v3';
import { events, emitEvent } from '../utilities/custom-events';
import { post } from '../utilities/router';
import { scrollTop } from '../utilities/scroll';

const RECAPTCHA_SITE_KEY = '6LeqSP0UAAAAALcKJcMy-etg3iWc8wwcgA5xfqnT';

export default class {
    constructor({
        elId,
        bodyHandle,
        messageHandle,
        focusedClass = 'is-focused',
        successEvent = null,
    }) {
        // Elements and class variables
        const el = document.getElementById(elId);
        const body = el.querySelector(bodyHandle);
        const message = el.querySelector(messageHandle);
        const form = el.querySelector('form');
        const allInputs = form.querySelectorAll('input, select, textarea');
        const inputs = Array.from(allInputs).filter(input => (
            input.type !== 'hidden' && input.type !== 'submit'
        ));
        const submit = form.querySelector('[type="submit"]');

        if (RECAPTCHA_SITE_KEY === '' || !form) {
            return;
        }

        let processing = false;

        // Helper function
        function addErrors(errors) {
            Array.from(inputs).forEach(input => {
                const name = input.getAttribute('name').replace('[]', '');
                const error = name in errors ? errors[name][0] : '';

                emitEvent(events.setInputError, { name, error });
            });
        }

        // Load reCAPTCHA object from key
        load(RECAPTCHA_SITE_KEY).then(r => {
            function handleSubmit(e) {
                e.preventDefault();

                if (processing) {
                    return;
                }

                processing = true;

                // Handle post response
                function cb(res) {
                    const json = JSON.parse(res);

                    message.innerHTML = json.message;
                    submit.blur();
                    submit.classList.remove(focusedClass);
                    scrollTop(el);

                    // Handle errors
                    if (json.errors) {
                        addErrors(json.errors);
                        processing = false;

                        return;
                    }

                    // All is well

                    // Handle gated downloads
                    if (json.downloadUrl) {
                        // Send success event
                        if (successEvent && typeof gtag === 'function') {
                            gtag('event', 'Download', successEvent); // eslint-disable-line no-undef
                        }

                        message.insertAdjacentHTML('beforeend', `
                            <a href="${json.downloadUrl}" title="Download" target="_blank" download>Download Your File</a>
                        `);
                    }

                    if (json.formName && window.dataLayer) {
                        window.dataLayer.push({
                            event: 'getWWevents',
                            eventAction: 'Form Submission',
                            eventCategory: json.formName,
                        });
                    }

                    // Hide body on success
                    if (body) body.remove();

                    // Disable current form
                    form.removeEventListener('submit', handleSubmit);
                    form.remove();
                }

                submit.classList.add(focusedClass);

                // Get reCAPTCHA score token
                r.execute('form').then(token => {
                    // Create hidden input in form to pass reCAPTCHA score token to server
                    const input = document.createElement('input');
                    input.setAttribute('type', 'hidden');
                    input.setAttribute('name', 'token');
                    input.setAttribute('value', token);

                    form.appendChild(input);

                    const formData = new FormData(form);
                    post({ formData, cb });
                });
            }

            form.addEventListener('submit', handleSubmit);
        });
    }
}
