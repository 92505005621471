import Player from '@vimeo/player';
import { isIos, isMobile } from '../utilities/devices';

const AR = 16 / 9;

export default class {
    constructor({
        id,
        bgVideoContHandle,
        readyClass = 'is-ready',
        playingClass = 'is-playing',
        hiddenClass = 'is-hidden',
        leftClass = 'has-left',
    }) {
        const el = document.getElementById(id);
        const bgVideoCont = el.querySelector(bgVideoContHandle);
        const bgVideo = bgVideoCont.querySelector('iframe');
        const video = el.querySelector('iframe');
        const playButton = el.querySelector('button');
        const bgPlayer = new Player(bgVideo);
        const player = new Player(video);
        const onMobile = isMobile() || isIos();

        let timer = null;

        function closeVideo({ keyCode }) {
            if (keyCode !== 27) { return; }

            el.classList.remove(playingClass);
            player.pause();
        }

        function setVideoSize() {
            if (el.offsetWidth / el.offsetHeight > 16 / 9) {
                bgVideoCont.style.width = `${el.offsetWidth}px`;
                bgVideoCont.style.height = `${el.offsetWidth / AR}px`;
            } else {
                bgVideoCont.style.width = `${el.offsetHeight * AR}px`;
                bgVideoCont.style.height = `${el.offsetHeight}px`;
            }
        }

        if (!onMobile) {
            el.onmouseenter = () => { el.classList.remove(leftClass); };
            el.onmouseleave = () => { el.classList.add(leftClass); };
            el.onmousemove = ({ clientX, clientY }) => {
                clearTimeout(timer);
                el.classList.remove(hiddenClass);
                timer = setTimeout(() => {
                    el.classList.add(hiddenClass);
                }, 3000);
                playButton.style.top = `${clientY - el.offsetTop}px`;
                playButton.style.left = `${clientX - el.offsetLeft}px`;
            };
        }
        window.addEventListener('resize', setVideoSize);
        playButton.onclick = () => {
            if (el.classList.toggle(playingClass)) {
                player.play();
                document.addEventListener('keyup', closeVideo);
            } else {
                player.pause();
                document.removeEventListener('keyup', closeVideo);
            }
        };

        setVideoSize();
        setTimeout(() => {
            el.classList.add(hiddenClass);
        }, 5000);
        bgPlayer.on('playing', () => { el.classList.add(readyClass); });
        bgPlayer.on('error', () => { el.classList.remove(readyClass); });
    }
}
