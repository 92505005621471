import { scrollTop } from '../utilities/scroll';

export default class {
    /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
    constructor({
        elId,
        progressHandle,
        scrollHandle,
        scrollContHandle,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const progress = el.querySelector(progressHandle);
        const scroll = el.querySelector(scrollHandle);
        const scrollContainer = document.querySelector(scrollContHandle) || window;
        const inModal = scrollContainer !== window;

        // Event handler functions
        function handleScroll() {
            const y = inModal
                ? scrollContainer.scrollTop
                : window.pageYOffset;
            const t = inModal
                ? scrollContainer.firstElementChild.offsetHeight
                : document.body.offsetHeight;
            const width = (y + window.innerHeight) / t;

            progress.style.transform = `scaleX(${width})`;
        }
        function handleClick() {
            scrollTop(document.body);
        }

        // Add event listeners
        scrollContainer.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        scroll.addEventListener('click', handleClick);

        // Initialize
        handleScroll();
    }
}
