const AUTH_USER = 'wrayward';
const AUTH_PASS = 'ww40th2017';

/**
 * Helper function to convery object to query string
 * @param {object} obj
 */
function toQueryString(obj = {}) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}

/**
 * Get shared markup
 * @param {string} url
 * @param {object} query
 * @param {object} state
 * @param {function} cb
 * @return {void}
 */
export function get({ url, query, state = null, cb = null }) {
    const xhr = new XMLHttpRequest();

    // Event handler functions
    function handleReadyStateChange() {
        if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 404)) {
            const contentType = xhr.getResponseHeader('Content-Type');

            // Update URL
            if (state && window.history && window.history.pushState) {
                // Replace state with flag to force reloading of page on popstate
                const replaceState = { ...window.history.state, reload: true };
                const stateUrl = state.query
                    ? `${state.url}?${toQueryString(state.query)}`
                    : state.url;

                // Increment history index count
                state.index = window.history.state.index + 1;

                window.history.replaceState(replaceState, null);
                window.history.pushState(state, state.metaTitle || null, stateUrl);
            }

            // Callback
            if (cb) cb(xhr.responseText, contentType);
        }
    }

    xhr.open('GET', `${url}?${toQueryString(query)}`);
    // Make sure Craft knows this is AJAX
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    // Add basic auth for staging
    xhr.setRequestHeader('Authorization', `Basic ${btoa(`${AUTH_USER}:${AUTH_PASS}`)}`);
    xhr.onreadystatechange = handleReadyStateChange;
    xhr.send();
}

/**
 * Add user section record and update topic tracker component
 * @param {string} url
 * @param {object} formData
 * @param {object} state
 * @param {function} cb
 * @return {void}
 */
export function post({ url = '/', formData, state = null, cb = null }) {
    const xhr = new XMLHttpRequest();

    // Event handler functions
    function handleReadyStateChange() {
        if (xhr.readyState === 4 && xhr.status === 200) {
            const contentType = xhr.getResponseHeader('Content-Type');

            // Update URL
            if (state && window.history && window.history.pushState) {
                // Replace state with flag to block reloading of page on popstate
                const replaceState = { ...window.history.state, reload: false };

                // Increment history index count
                state.index = window.history.state.index + 1;

                window.history.replaceState(replaceState, null);
                window.history.pushState(state, null);
            }

            // Callback
            if (cb) cb(xhr.responseText, contentType);
        }
    }

    xhr.open('POST', url);
    // Make sure Craft knows this is AJAX
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    // Add basic auth for staging
    xhr.setRequestHeader('Authorization', `Basic ${btoa(`${AUTH_USER}:${AUTH_PASS}`)}`);
    xhr.onreadystatechange = handleReadyStateChange;
    xhr.send(formData);
}
