import debounce from '../utilities/debouncer';
import { events } from '../utilities/custom-events';

export default class {
    constructor({
        elId,
        triggerHandle,
        contHandle,
        activeClass = 'is-active',
        concealFooter,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const trigger = el.querySelector(triggerHandle);
        const cont = el.querySelector(contHandle);
        const links = el.querySelectorAll('a');
        const threshold = 5;

        // State variables
        let conceal = concealFooter;
        let height = cont.offsetHeight;
        let atBottom = conceal
            ? false
            : trigger.getBoundingClientRect().top - window.innerHeight < threshold;

        // Event handler functions
        function handleResize() {
            height = cont.offsetHeight;
        }
        const handleScroll = debounce(() => {
            atBottom = conceal
                ? false
                : trigger.getBoundingClientRect().top - window.innerHeight < threshold;

            el.style.paddingBottom = atBottom ? `${height}px` : 0;
        }, 500);
        function handleUpdateFooter(e) {
            Array.from(links).forEach(link => {
                link.classList.remove(activeClass);
            });

            // Update state variables
            conceal = e.detail.concealFooter;

            handleScroll();
        }

        // Add event listeners
        window.addEventListener(events.updateFooter, handleUpdateFooter);
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);

        // Initialize
        handleUpdateFooter({ detail: { concealFooter: conceal } });
    }
}
