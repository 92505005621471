import Isotope from 'isotope-layout';
import { getBreakpoint } from '../utilities/breakpoints';
import { events, emitEvent } from '../utilities/custom-events';
import debounce from '../utilities/debouncer';

export default class {
    constructor({
        elId,
        lightboxHandle,
        prevHandle,
        nextHandle,
        loaderHandle,
        activeClass = 'is-active',
        focusedClass = 'is-focused',
    }) {
        // Elements and class variables
        const el = document.getElementById(elId);
        const grid = el.querySelector('ul');
        const imgs = grid.querySelectorAll('img');
        const lightbox = el.querySelector(lightboxHandle);
        const lightboxImg = lightbox.querySelector('img');
        const prev = el.querySelector(prevHandle);
        const next = el.querySelector(nextHandle);
        const loader = el.querySelector(loaderHandle);

        // State variables
        let activeIndex = null;
        let iso = null;

        // Helper functions
        function setLightboxImg() {
            const src = imgs[activeIndex].getAttribute('data-src');

            loader.classList.add(focusedClass);
            lightboxImg.setAttribute('src', src);
        }

        // Event handler functions
        function handleLoad() {
            lightboxImg.style.width = '100%';
            lightboxImg.style.height = 'auto';

            if (lightboxImg.offsetHeight > window.innerHeight) {
                lightboxImg.style.width = 'auto';
                lightboxImg.style.height = '100vh';
            }

            loader.classList.remove(focusedClass);
        }
        const handleLoads = Array.from(imgs).map(() => () => {
            if (iso === null) return;

            iso.layout();
        });
        const handleOpens = Array.from(imgs).map((img, i) => () => {
            if (getBreakpoint() === 'sm') return;

            activeIndex = i;

            setLightboxImg();
            emitEvent(events.lockScroll);
            lightbox.classList.add(activeClass);
        });
        function handlePrev(e) {
            e.stopPropagation();

            activeIndex = activeIndex === 0 ? imgs.length - 1 : activeIndex - 1;

            setLightboxImg();
        }
        function handleNext(e) {
            e.stopPropagation();

            activeIndex = activeIndex === imgs.length - 1 ? 0 : activeIndex + 1;

            setLightboxImg();
        }
        function handleClose() {
            emitEvent(events.unlockScroll);
            lightbox.classList.remove(activeClass);
        }
        const handleResize = debounce(() => {
            if (getBreakpoint() === 'sm') {
                if (iso === null) return;

                iso.destroy();
                iso = null;
                handleClose();
            } else {
                if (iso !== null) return;

                iso = new Isotope(grid);
            }
        }, 300);

        // Add event listeners
        Array.from(imgs).forEach((img, i) => {
            img.addEventListener('load', handleLoads[i]);
            img.addEventListener('click', handleOpens[i]);
        });
        prev.addEventListener('click', handlePrev);
        next.addEventListener('click', handleNext);
        lightbox.addEventListener('click', handleClose);
        lightboxImg.addEventListener('load', handleLoad);
        window.addEventListener('resize', handleResize);

        // Initialize grid
        handleResize();
    }
}
