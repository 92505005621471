import { events, emitEvent } from '../utilities/custom-events';
import { getNavOffset } from '../utilities/breakpoints';
import { get, post } from '../utilities/router';
import { scrollTo, scrollTop } from '../utilities/scroll';

export default class {
    /**
     * Class contructor uses es6 destructoring with default parametes
     * @param {Object} config
     */
    constructor({
        elId,
        actionHandle,
        activeClass,
        actionType,
        stateVariables,
        center,
        offsetScroll,
        url,
        view,
        anchorId,
        eventType = 'Click',
        eventConfig = null,
        trackingPixelUrl,
        state,
    }) {
        // Elements and class variables
        const el = document.getElementById(elId);
        const action = el.querySelector(actionHandle);

        // Event handler functions
        function handleCloseView(e) {
            if (e.detail.view !== view) return;

            action.classList.remove(activeClass);

            window.removeEventListener(events.closeView, handleCloseView);
        }

        function handleClick(e) {
            function launchAnchor() {
                const offset = offsetScroll ? getNavOffset() : 0;

                function cb() {
                    action.blur();
                    state.index++;
                }

                scrollTo(anchorId, offset, cb);
            }

            // Start route change
            function launchRoute() {
                const query = { view };
                const historyState = { url, view, offsetScroll, ...stateVariables };

                function cb(res) {
                    const markup = res;

                    action.blur();
                    emitEvent(events.loadView, {
                        view,
                        markup,
                        offset: offsetScroll,
                        ...stateVariables,
                    });
                }
                emitEvent(events.closeView, { view: 'modal' });
                emitEvent(events.closeView, { view: 'sidebar' });
                emitEvent(events.unloadView, { view });

                get({ url, query, state: historyState, cb });
            }

            // Launch function for UI case callback
            function launchView() {
                const form = el.querySelector('form');
                const formData = new FormData(form);
                const historyState = { url, view };

                action.focus();
                action.classList.add(activeClass);

                window.addEventListener(events.closeView, handleCloseView);

                // Check if current link is already loaded in view before fetching content
                if (state[view] && state[view].activeLink === elId) {
                    action.blur();

                    if (window.history && window.history.pushState) {
                        window.history.pushState(historyState, null);
                    }

                    emitEvent(events.openView, { view });

                    return;
                }

                // Handle post response
                function cb(res) {
                    action.blur();

                    const markup = JSON.parse(res).content;

                    // Update state content
                    state[view] = {
                        ...state[view],
                        activeLink: elId,
                    };

                    emitEvent(events.loadView, { view, markup, ...stateVariables });
                }

                post({ url, formData, state: historyState, cb });
            }

            // Send click event
            if (eventConfig && typeof gtag === 'function') {
                gtag('event', eventType, eventConfig); // eslint-disable-line no-undef
            }

            switch (actionType) {
            case 'anchor':
                e.preventDefault();

                launchAnchor();

                break;
            case 'route':
                e.preventDefault();

                launchRoute();

                break;
            case 'ui':
                e.preventDefault();

                if (center) {
                    const scrollOffset = (window.innerHeight / 2) - (action.offsetHeight / 2);

                    scrollTop(action, scrollOffset, launchView);
                } else {
                    launchView();
                }

                break;
            default:
                action.blur();

                break;
            }
        }

        function handleTracking() {
            // Append tracking pixel to body
            const img = `
                <img
                height="1" width="1" alt=""
                style="position:absolute;border-style:none;"
                src="${trackingPixelUrl}" />
            `;
            document.body.insertAdjacentHTML('beforeend', img);
        }

        // Add event listeners
        action.addEventListener('click', handleClick);

        if (trackingPixelUrl) {
            action.addEventListener('click', handleTracking);
        }
    }
}
