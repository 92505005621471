import { events } from '../utilities/custom-events';
import { scrollBottom } from '../utilities/scroll';

export default class {
    constructor({
        elId,
        scrollHandle,
        fixedClass,
        peekClass,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const scroll = el.querySelector(scrollHandle);

        // State variables
        let lastY = window.pageYOffset;

        // Event handler function
        function handleScroll() {
            if (el.getBoundingClientRect().bottom < 0) {
                el.classList.add(peekClass);
            } else {
                el.classList.remove(peekClass);
            }

            if (el.getBoundingClientRect().bottom < 0 && window.pageYOffset - lastY < 0) {
                el.classList.add(fixedClass);
            } else {
                el.classList.remove(fixedClass);
            }
            lastY = window.pageYOffset;
        }
        function handleUnload(e) {
            const { view } = e.detail;

            if (view !== 'page' && view !== 'article') return;

            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener(events.unloadView, handleUnload);
        }
        function handleClick() {
            scrollBottom(el);
        }

        // Enable event listeners
        window.addEventListener('scroll', handleScroll);
        window.addEventListener(events.unloadView, handleUnload);
        if (scroll) scroll.addEventListener('click', handleClick);

        // Initalize
        handleScroll();
    }
}
