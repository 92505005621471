/**
 * API documention for Vimeo player:
 * https://github.com/vimeo/player.js
 */

import Player from '@vimeo/player';
import { events } from '../utilities/custom-events';

export default class {
    constructor({
        elId,
        playPixelUrl = '',
        playInline,
    }) {
        const el = document.getElementById(elId);
        const iframe = el.querySelector('iframe');
        const player = new Player(iframe);

        // Helper functions
        function playVideo() {
            player.play();
        }
        function pauseVideo() {
            player.pause();
        }

        function handleOpenView(e) {
            e.preventDefault();

            if (playInline || e.detail.view !== 'modal') return;

            playVideo();
        }
        function handleCloseView(e) {
            e.preventDefault();

            if (e.detail.view !== 'modal') return;

            pauseVideo();
        }
        function handlePlay() {
            // Append tracking pixel to body
            const img = `
                <img
                height="1" width="1" alt=""
                style="position:absolute;border-style:none;"
                src="${playPixelUrl}" />
            `;
            document.body.insertAdjacentHTML('beforeend', img);
        }

        // Add event listeners
        window.addEventListener(events.openView, handleOpenView);
        window.addEventListener(events.closeView, handleCloseView);
        if (playPixelUrl !== '') {
            player.on('play', handlePlay);
        }
    }
}
