import { getBreakpoint } from '../utilities/breakpoints';
import { events } from '../utilities/custom-events';
import { injectMarkup } from '../utilities/components';
import { get } from '../utilities/router';
import { scrollTop } from '../utilities/scroll';

export default class {
    /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
    constructor({
        elId,
        searchHandle,
        postsContHandle,
        prevHandle,
        nextHandle,
        fixedClass,
        filterSlugs,
        template,
        metaTitle,
        currentPage,
        currentSearch,
        lazyLoad,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const search = el.querySelector(searchHandle);
        const postsCont = el.querySelector(postsContHandle);
        const prev = el.querySelector(prevHandle);
        const next = el.querySelector(nextHandle);
        // Clear out empty filter slugs
        const filters = filterSlugs.filter(slug => slug);

        // State variables
        let fetching = false;
        let page = currentPage;
        let q = currentSearch;
        let complete = false;

        // Helper functions
        function checkPage(res = null) {
            const prevFlag = res
                ? res.includes('data-prev="true"')
                : postsCont.querySelector('[data-prev="true"]');
            const nextFlag = res
                ? res.includes('data-next="true"')
                : postsCont.querySelector('[data-next="true"]');

            prev.blur();
            next.blur();
            prev.style.display = prevFlag && !lazyLoad ? 'inline-block' : 'none';
            next.style.display = nextFlag ? 'inline-block' : 'none';

            if (!nextFlag) complete = true;
        }
        function fetchPosts(append = false) {
            const title = q ? `${metaTitle} - Search ${q}` : `${metaTitle} - Page ${page}`;
            const url = `${template}/${filters.join('/')}`;
            const query = { page, q };
            const state = !lazyLoad
                ? {
                    url: `${window.location.origin}${window.location.pathname}`,
                    query,
                    view: 'article',
                    offset: true,
                    metaTitle: title,
                }
                : null;

            function cb(res) {
                fetching = false;

                if (!append) scrollTop(el);
                document.title = title;
                injectMarkup(postsCont, res, append);
                checkPage(res);
            }

            get({ url, query, state, cb });
        }

        // Event handler functions
        function handleScroll() {
            // Handle fixing nav
            if (getBreakpoint() !== 'sm' && el.getBoundingClientRect().top < 0) {
                el.classList.add(fixedClass);
            } else {
                el.classList.remove(fixedClass);
            }

            if (
                !lazyLoad || complete || fetching
                || next.getBoundingClientRect().top > window.innerHeight
            ) return;

            // Animate next button
            next.focus();

            // Update state variables
            fetching = true;
            page++;

            fetchPosts(true);
        }
        function handleUnload(e) {
            const { view } = e.detail;

            if (view !== 'page' && view !== 'article') return;

            window.removeEventListener(events.unloadView, handleUnload);
            window.removeEventListener('scroll', handleScroll);
        }
        function handleSubmit(e) {
            if (fetching) return;

            e.preventDefault();

            // Update state variables
            fetching = true;
            page = 1;
            q = e.currentTarget.q.value;

            fetchPosts();
        }
        function handlePrev(e) {
            if (fetching) return;

            e.preventDefault();

            // Update state variables
            fetching = true;
            page--;

            fetchPosts();
        }
        function handleNext(e) {
            if (fetching) return;

            e.preventDefault();

            // Update state variables
            fetching = true;
            page++;

            fetchPosts();
        }

        // Add event listeners
        window.addEventListener('scroll', handleScroll);
        window.addEventListener(events.unloadView, handleUnload);
        if (!lazyLoad) {
            prev.addEventListener('click', handlePrev);
            next.addEventListener('click', handleNext);
        }
        if (search) search.addEventListener('submit', handleSubmit);

        // Initialize
        checkPage();
    }
}
