import { events, emitEvent } from '../utilities/custom-events';
import { get } from '../utilities/router';
import { injectMarkup, clearMarkup } from '../utilities/components';
import { scrollTop } from '../utilities/scroll';

const ANIMATION_DURATION = 800; // miliseconds
const REFRESH_RATE = ANIMATION_DURATION / 60;

export default class {
    /**
   * Class contructor uses es6 destructoring with default parametes
   * @param {Object} config
   */
    constructor({
        elId,
        lockedClass,
        metaTitle,
        state,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);

        // State variables
        let article = el.querySelector('article');

        // Event handler functions
        function handleLoadContent(e) {
            const { view } = e.detail;

            if (view !== 'page' && view !== 'article') return;

            if (state.animating || state.scrolling) {
                setTimeout(handleLoadContent.bind(this, e), REFRESH_RATE);

                return;
            }

            const cont = view === 'article' ? article : el;
            const {
                metaTitle: title,
                markup,
                fadeHeader,
                invertHeader,
                concealHeaderLinks,
                headerCta,
                concealHeaderCta,
                concealFooter,
            } = e.detail;

            injectMarkup(cont, markup);

            // Update state variables
            state.index = window.history.state.index;
            article = el.querySelector('article');

            // Fire pageView
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'virtualPageView',
                    virtualPagePath: window.location.pathname,
                    virtualPageTitle: title,
                });
            }

            // Update global variables
            document.title = title;
            emitEvent(events.updateHeader, {
                fadeHeader,
                invertHeader,
                headerCta,
                concealHeaderLinks,
                concealHeaderCta,
            });
            emitEvent(events.updateFooter, { concealFooter });
        }
        function handleUnloadContent(e) {
            const { view } = e.detail;

            if (view !== 'page' && view !== 'article') return;

            const cont = view === 'article' ? article : el;

            function scroll() {
                state.scrolling = true;

                scrollTop(cont, 0, () => {
                    state.scrolling = false;
                });
            }

            function animate() {
                state.animating = true;

                clearMarkup(cont);

                setTimeout(() => {
                    state.animating = false;

                    scroll();
                }, ANIMATION_DURATION);
            }

            // Animate page unload
            animate();
        }
        function handleTouchMove(e) {
            e.preventDefault();
        }
        function handleLockScroll() {
            document.body.classList.add(lockedClass);
            // Prevent scroll on iOS
            document.addEventListener('touchmove', handleTouchMove);
        }
        function handleUnlockScroll() {
            document.body.classList.remove(lockedClass);
            // Enable scroll on iOS
            document.removeEventListener('touchmove', handleTouchMove);
        }
        function handlePopState(e) {
            if (!e.state) return;

            const {
                reload = true,
                url,
                query,
                view,
                index,
                offsetScroll = false,
                ...stateVariables
            } = e.state;

            if (view !== 'page' && view !== 'article') return;

            emitEvent(events.closeView, { view: 'modal' });
            emitEvent(events.closeView, { view: 'sidebar' });

            // If reload flag is false and going backwards, don't reload
            if (!reload && index <= state.index) {
                state.index = index;

                return;
            }

            function cb(res) {
                const markup = res;

                emitEvent(events.loadView, { view: 'page', markup, offset: offsetScroll, ...stateVariables });
            }

            emitEvent(events.unloadView, { view: 'page' });
            get({ url, query, cb });
        }

        // Add event listeners
        window.addEventListener(events.loadView, handleLoadContent);
        window.addEventListener(events.unloadView, handleUnloadContent);
        window.addEventListener(events.lockScroll, handleLockScroll);
        window.addEventListener(events.unlockScroll, handleUnlockScroll);

        // Set popstate event listener
        if (window.history) window.addEventListener('popstate', handlePopState);

        // Set initial state
        if (window.history && window.history.replaceState) {
            const replaceState = {
                ...window.history.state,
                url: window.location.href,
                view: 'page',
                index: 0,
                metaTitle,
            };

            state.index = replaceState.index;

            window.history.replaceState(replaceState, null);
        }
    }
}
