import debounce from '../utilities/debouncer';
import { getBreakpoint } from '../utilities/breakpoints';

const TRACK_BPS = ['lg', 'xl'];

export default class {
    constructor({
        elId,
        shareLinksHandle,
        fixedClass,
        pastClass,
    }) {
        const el = document.getElementById(elId);
        const shareLinks = el.querySelector(shareLinksHandle);

        let track = TRACK_BPS.includes(getBreakpoint());
        let style = el.currentStyle || window.getComputedStyle(el);
        let offset = parseInt(style.marginTop, 10);

        // Event handler functions
        function handleScroll() {
            const elRect = el.getBoundingClientRect();
            const shareLinksRect = shareLinks.getBoundingClientRect();

            if (!track) return;

            // Check if el is past top of window
            if (elRect.top - offset < 0) {
                el.classList.add(fixedClass);
            } else {
                el.classList.remove(fixedClass);
            }
            // Check if bottom of share links is at bottom of cont
            if (shareLinksRect.bottom > elRect.bottom || shareLinksRect.top < offset) {
                el.classList.add(pastClass);
            } else {
                el.classList.remove(pastClass);
            }
        }
        const handleResize = debounce(() => {
            // Update state variables
            track = TRACK_BPS.includes(getBreakpoint());
            style = el.currentStyle || window.getComputedStyle(el);
            offset = parseInt(style.marginTop, 10);

            if (track) handleScroll();
        }, 500);

        // Enable event listeners
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        // Initialize
        handleScroll();
    }
}
