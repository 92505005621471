import { injectMarkup } from '../utilities/components';
import { get } from '../utilities/router';
import { scrollTop } from '../utilities/scroll';

export default class {
    constructor({
        elId,
        linkHandle,
        playHandle,
        focusedClass = 'is-focused',
        template,
        videoSlug,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const link = el.querySelector(linkHandle);
        const play = el.querySelector(playHandle);

        // Event handler functions
        function handleClick(e) {
            e.preventDefault();

            const url = template;
            const query = { videoSlug, playInline: true };
            const scrollOffset = (window.innerHeight / 2) - (el.offsetHeight / 2);

            // Handle post response
            function cb(res) {
                injectMarkup(el, res);
            }

            function getVideo() {
                get({ url, query, cb });
            }

            play.classList.add(focusedClass);
            scrollTop(el, scrollOffset, getVideo);
        }

        // Add event listeners
        link.addEventListener('click', handleClick);
    }
}
